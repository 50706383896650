/* tslint:disable */
import React from "react";
import { Box, Divider, Typography, Chip } from '@material-ui/core';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, YAxisProps, Label } from 'recharts'
import { ColourHash } from '../../app/helpers';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


interface ChartAntibiogramProps {
	data: any[]
	keyCollection: any[]
	colourLookup: any
	onClick: Function
}

const CustomTooltip = (props:any) => {
	if (props.payload && props.payload[0] != null) 
	{
		const newPayload = props.payload.map((payload)=>{
			var newPayload =  { ...payload };
			
			if( payload.payload[payload.dataKey+'Susceptible'] )
			{
				newPayload.value = payload.value + '% (' + payload.payload[payload.dataKey+'Susceptible'] + '/' + payload.payload[payload.dataKey+'Total'] + ')'; 
			}
			else
			{
				newPayload.value = "No Results";
			}

			return newPayload;
		});

		return <DefaultTooltipContent {...props} label={""} payload={newPayload}  />;
	}
  
	return <DefaultTooltipContent {...props} />;
};


const tickFormatter = (value) => {
	var keyName = "";
	switch( value )
	{
		case -1:
			keyName = 'Not Set';
			break;

		case 1:
			keyName = "First";
			break;

		case 2:
			keyName = "Second";
			break;

		case 3:
			keyName = "Third";
			break;

		case 4:
			keyName = "Fourth";
			break;

		default: 
			keyName = 'Error';
			break;
	}

	return keyName;
}


const ChartAntibiogram = (props:ChartAntibiogramProps) => {
	const handleClick = (data:any) => {
		props.onClick(data.activeLabel);
	}

	return (
		<>
			<ResponsiveContainer height={(props.data.length * 40) + 40} width="100%">
				<BarChart data={props.data}  layout="vertical" onClick={handleClick}>
					<CartesianGrid strokeDasharray="3 3"/>
					<XAxis type="number"  axisLine={false}  />
					<YAxis type="category" dataKey="level" tickFormatter={tickFormatter} orientation="left" />
					<Tooltip content={<CustomTooltip/>} offset={24} />
					{props.keyCollection.map((key:any)=>{
						return <Bar key={key} dataKey={key} fill={props.colourLookup[key] === undefined ? ColourHash(key) : props.colourLookup[key]}  />;
					})}
				</BarChart>
			</ResponsiveContainer>
		</>
	);
}

export default ChartAntibiogram;