import React, { useEffect, useState } from 'react';
import { Card, CardContent,	makeStyles,	Box, Container, Typography, Button } from '@material-ui/core';
import { getBioSystemCollection, getRegionCollection, getAgeCollection, getSeverityCollection } from '../Settings/SettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import { RootState } from '../../app/store';
import { Formik } from 'formik';
import * as Yup from "yup";
import SelectFormControl from "../../components/SelectFormControl";
import { useNavigate } from 'react-router-dom';
import { updateSearch } from './SearchSlice'
import { getUser } from "../../features/User/UserSlice";



const useStyles = makeStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		height: '100%',
	}
}));

const genderOptions = [
	{ value: '3', label: 'Male' },
	{ value: '2', label: 'Female' },
]

const SearchView = () => {
	const user = useSelector((state: RootState) => getUser(state));
	const classes = useStyles();
	const navigate = useNavigate();
	const bioSystemCollection = useSelector((state: RootState) => getBioSystemCollection(state));
	const regionCollection = useSelector((state: RootState) => getRegionCollection(state));
	const severityCollection = useSelector((state: RootState) => getSeverityCollection(state));
	const ageCollection = useSelector((state: RootState) => getAgeCollection(state));
	const dispatch = useDispatch();
	
	const groupedAgesOptions = [
		{
		  label: 'Broad Ages',
		  options: ageCollection.filter(x=>x.group === "Broad Ages"),
		},
		{
		  label: 'Ages',
		  options: ageCollection.filter(x=>x.group === "Ages"),
		},
	  ]
	return (
		<Page className={classes.card}>
			<Formik
      			initialValues={{
					biosystem: [],
					gender: "",
					age: "",
					region: [user.userregion],
					severity: ""
				}}
				validationSchema={Yup.object().shape({
					biosystem: Yup.array().min(1, "Biosystem is required"),
					gender: Yup.string().required("Gender is required"),
					age: Yup.string().required("Age is required"),
					region: Yup.array().min(1, "Region is required"),
					severity: Yup.string().required("Severity is required"),
				})}
				onSubmit={(values: any) => {
					dispatch(updateSearch({
						biosystem: bioSystemCollection.filter((data)=>values.biosystem.includes(data.value)),
						gender: genderOptions.filter((data)=>values.gender === data.value),
						region: regionCollection.filter((data)=>values.region.includes(data.value)),
						age: ageCollection.filter((data)=>values.age === data.value),
						severity: severityCollection.filter((data)=>values.severity === data.value)
					}));

					navigate('/result');		
				}}
    		>{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				setFieldValue,
				setFieldTouched,
				isSubmitting,
				touched,
				values,
			}) => (<form onSubmit={handleSubmit} style={{height: '100%', marginTop:-64}}><Box
						display="flex"
						flexDirection="column"
						height="100%"
						justifyContent="center"
					>
						<Container maxWidth="sm">
							<Card style={{overflow: 'visible'}}>
								<CardContent>
									<Typography variant="h2">Let's do a search</Typography>
									<Typography variant="subtitle2">
										Find the most effective antibiotic for your patient based on real time data and case attributes.
									</Typography>

									<SelectFormControl isMulti name="biosystem" label="Biosystem" options={bioSystemCollection} />
									<SelectFormControl name="gender" label="Gender" options={genderOptions} />
									<SelectFormControl name="age" label="Age" options={groupedAgesOptions} />
									<SelectFormControl isMulti name="region" label="Region" options={regionCollection} />
									<SelectFormControl name="severity" label="Severity" options={severityCollection} />

									<Box mt={2}><Button variant="contained" type="submit" >Search</Button></Box>
								</CardContent>
							</Card>
						</Container>
					</Box></form>)}
			</Formik>
		</Page>
	);
  };

export default SearchView;

