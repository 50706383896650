import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import { RootState } from '../../app/store';


// setSelectedBioSystem(bioSystemCollection.filter((data)=>values.biosystem.includes(data.value)));
					// setSelectedGender(genderOptions.filter((data)=>values.gender === data.value));
					// setSelectedRegion(regionCollection.filter((data)=>values.region.includes(data.value)));
					// setSelectedAge(ageCollection.filter((data)=>values.age === data.value));

interface SearchState {
	biosystem: any[],
	gender: any[],
	region: any[],
	age: any[],
	severity: any[]
}

const initialState: SearchState = {
	biosystem: [],
	gender: [],
	region: [],
	age: [],
	severity: []
};




export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		
		updateSearch(state, action) {
			state.age = action.payload.age;
			state.biosystem = action.payload.biosystem;
			state.region = action.payload.region;
			state.gender = action.payload.gender;
			state.severity = action.payload.severity;
		}
	},
	extraReducers: {

	
		
		
	},
});


export const { updateSearch } = searchSlice.actions

export default searchSlice.reducer;