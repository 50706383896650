import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

import { RootState } from '../../app/store';
import { ApiBaseUrl } from '../../app/helpers';

function setCookie(name:string,value:string,seconds:number) {
    var expires = "";
    if (seconds) {
        var date = new Date();
        date.setTime(date.getTime() + (seconds*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name:string) : string | undefined {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0){
			return c.substring(nameEQ.length,c.length);
		}
    }
    return undefined;
}


interface UserState {
	user: any;
	jwtToken?: string;
	loginStatus: string;
	loadStatus: string;
	loginErrorStatus: string;
	updateStatus: string;
	updatePasswordStatus: string;
	adminMode: boolean;
}

const initialState: UserState = {
	user: null,
	jwtToken: getCookie('jwt:auth'),
	loginStatus: 'idle',
	loadStatus: 'idle',
	loginErrorStatus: '',
	updateStatus: 'idle',
	updatePasswordStatus: 'idle',
	adminMode: false
};


export const loadUser = createAsyncThunk('/api/auth/me', async () => {
	if( getCookie('jwt:auth') === undefined )
	{
		return null;
	}
	return axios.get(`${ApiBaseUrl}/api/auth/me`, {
			headers: { 
				Authorization: `Bearer ${getCookie('jwt:auth')}`,
				Accept: 'application/json, text/plain',
				'Content-Type': 'application/json'
			 },
		}
	)
});


export const loginUser = createAsyncThunk(
	'users/login',
	// if you type your function argument here
	async (loginDetail:any, thunkApi) => {
		return axios.post(`${ApiBaseUrl}/api/auth/login`, {
			email: loginDetail.email,
			password: loginDetail.password
		}).then((response) => {
			setCookie("jwt:auth", response.data.access_token, response.data.expires_in);
			return response.data;
		});
	}
)

export const acceptInvite = createAsyncThunk(
	'users/login',
	// if you type your function argument here
	async (loginDetail:any, thunkAPI) => {
		return axios.post(`${ApiBaseUrl}/api/invite/accept`, loginDetail).then((response) => {
			setCookie("jwt:auth", response.data.access_token, response.data.expires_in);
			thunkAPI.dispatch(loadUser());
			return response.data;
		});
	}
)

export const updateSettings = createAsyncThunk(
	'users/updateSettings',
	// if you type your function argument here
	async (userSettings:any, thunkAPI) => {
		return axios.post(`${ApiBaseUrl}/api/auth/updatesettings`, userSettings, {
			headers: { 
				Authorization: `Bearer ${getCookie('jwt:auth')}`,
				Accept: 'application/json, text/plain',
				'Content-Type': 'application/json'
			 },
		}).then((response) => {
			
			return response.data;
		});
	}
)

export const updatePassword = createAsyncThunk(
	'users/updatePassword',
	// if you type your function argument here
	async (userSettings:any, thunkAPI) => {
		return axios.post(`${ApiBaseUrl}/api/auth/updatepassword`, userSettings, {
			headers: { 
				Authorization: `Bearer ${getCookie('jwt:auth')}`,
				Accept: 'application/json, text/plain',
				'Content-Type': 'application/json'
			 },
		}).then((response) => {
			
			return response.data;
		});
	}
)


export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAdminMode: (state, action) => {

			state.adminMode = action.payload;
		  },
	},
	extraReducers: {

		[loginUser.pending.toString()]: (state, action) => {
			state.loginStatus = 'loading'
		},
		[loginUser.fulfilled.toString()]: (state, action) => {
			state.loginStatus = 'succeeded';
			state.user = action.payload;
		},
		[loginUser.rejected.toString()]: (state, action) => {
			state.loginStatus = 'failed'

			//state.error = action.payload
		},
		[loadUser.fulfilled.toString()]: (state, action) => {
			if( action.payload )
			{
				state.user = action.payload.data;

				state.user.isAdmin = false;
				if( state.user.email.includes('@prospectorbiomedical.com') || state.user.email.includes('@onlinemind.net') )
				{
					state.user.isAdmin = true;
				}
			}
			state.loadStatus = 'succeeded';
			
		},
		[loadUser.pending.toString()]: (state, action) => {
			state.loadStatus = 'loading';
		},
		[loadUser.rejected.toString()]: (state, action) => {
			state.loadStatus = 'failed';
		},
		[updateSettings.fulfilled.toString()]: (state, action) => {
			state.updateStatus = 'succeeded';
		},
		[updateSettings.pending.toString()]: (state, action) => {
			state.updateStatus = 'loading';
		},
		[updateSettings.rejected.toString()]: (state, action) => {
			state.updateStatus = 'failed';
		},
		[updatePassword.fulfilled.toString()]: (state, action) => {
			state.updatePasswordStatus = 'succeeded';
		},
		[updatePassword.pending.toString()]: (state, action) => {
			state.updatePasswordStatus = 'loading';
		},
		[updatePassword.rejected.toString()]: (state, action) => {
			state.updatePasswordStatus = 'failed';
		},

		
		
	},
});


export const getUser = (state:RootState) => state.user.user;

export const getAdminMode = (state:RootState) => state.user.adminMode;


export const { setAdminMode } = userSlice.actions;

export default userSlice.reducer;