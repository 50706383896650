import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';

import { Box, Button, Container, TextField, Typography, makeStyles, CircularProgress,Checkbox,FormHelperText,Link,FormControl, Select, MenuItem, InputLabel, Grid } from '@material-ui/core';

import Page from '../../components/Page';
import { acceptInvite, getUser, loginUser } from './UserSlice';
import {useParams} from "react-router-dom";
import axios from 'axios';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ApiBaseUrl } from '../../app/helpers';
import { getRegionCollection } from '../Settings/SettingsSlice';




const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const spinnerStyles = makeStyles((theme) => ({
	root: {
	  color: '#ffffff',
	  marginLeft: '1em'
	},
  }));

const PractiseInviteView = () => {
  const classes = useStyles();
  const {inviteKey} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const spinnerClasses = spinnerStyles();

  const user = useSelector((state: RootState) => getUser(state));
  const regionCollection = useSelector((state:RootState) => getRegionCollection(state));

  const [practise, setPractise] = useState({} as any);
  const [errorStatus, setErrorStatus] = useState();

  

  useEffect(() => {
	if( user !== null )
	{
	  navigate('/', { replace: true });
	} 
 });



	useEffect(() => {
		axios.get(`${ApiBaseUrl}/api/invite/detail`, {
			params: {
				key: inviteKey,
			}
		}).then((response)=>{
			setPractise(response.data);
		}).catch(error => {
			setErrorStatus(error.message);
		});

	}, []);

	if( errorStatus )
	{
		return (
			<Page className={classes.root} title="Login" >
				<Box  display="flex"  flexDirection="column"  height="100%"	  justifyContent="center">
		  			<Container maxWidth="sm">
					  <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Invite already accepted.
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Your invite has already been accept please contact support.
                  </Typography>
                </Box>
			  		</Container>
			  	</Box>
			</Page>
		);
	}

	if( practise.name == null )
	{
		return null;
	}

  return (
    <Page
      className={classes.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
		<Formik
            initialValues={{
              email: '',
              name: '',
              password: '',
			  policy: false,
			  communicareid: '',
			  ahpranumber: '',
			  defaultregion: ''
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                name: Yup.string().max(255).required('Name is required'),
                password: Yup.string().max(255).required('password is required'),
				policy: Yup.boolean().oneOf([true], 'This field must be checked'),
				ahpranumber: Yup.string().max(255).required('Ahpra number is required'),
				communicareid: Yup.string().max(255).required('Communicare Id is required'),
				defaultregion: Yup.string().max(255).required('A default region is required'),
              })
            }
            onSubmit={(values: any) => {
				dispatch(acceptInvite({
					email: values.email,
					name: values.name,
					password: values.password,
					key: inviteKey,
					communicareid: values.communicareid,
					ahpranumber: values.ahpranumber
				}));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Finish signup {practise.name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
				<TextField
                  label="Practice Name"
                  margin="normal"
                  name="practise"
                  value={practise.name}
				  variant="outlined"
				  disabled
				  fullWidth
                />

                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

				<Grid container spacing={6} wrap="wrap">
					<Grid item md={6} sm={12}>
					<TextField
						error={Boolean(touched.ahpranumber && errors.ahpranumber)}
						fullWidth
						helperText={touched.ahpranumber && errors.ahpranumber}
						label="Ahpra Number"
						margin="normal"
						name="ahpranumber"
						onBlur={handleBlur}
						onChange={handleChange}
						type="ahpranumber"
						value={values.ahpranumber}
						variant="outlined"
						/>
					</Grid>
					<Grid item md={6} sm={12}>
						<TextField
							error={Boolean(touched.communicareid && errors.communicareid)}
							fullWidth
							helperText={touched.communicareid && errors.communicareid}
							label="Communicare Id"
							margin="normal"
							name="communicareid"
							onBlur={handleBlur}
							onChange={handleChange}
							type="communicareid"
							value={values.communicareid}
							variant="outlined"
						/>
					</Grid>
						
				</Grid>

				<FormControl variant="outlined" margin="normal" fullWidth
						error={Boolean(touched.defaultregion && errors.defaultregion)}
						>
					<InputLabel id="demo-simple-select-outlined-label">
					Default Region
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						label="Default Region"
						name="defaultregion"

						onBlur={handleBlur}
						onChange={handleChange}
						value={values.defaultregion}
					>
						{regionCollection.map((region)=>(
							<MenuItem value={region.value}>{region.label}</MenuItem>
						))}
					
					</Select>
					<FormHelperText>{touched.defaultregion && errors.defaultregion}</FormHelperText>
				</FormControl>
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component="a"
					  target="_blank"
                      href="https://doc.janus.healthcare/eula/"
                      underline="always"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default PractiseInviteView;
