import React from "react";
import { Typography } from '@material-ui/core';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts'
import { ColourHash } from '../../app/helpers';
import ShowPercentTooltip from './ShowPercentTooltip';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


interface ChartAntibioticsProps {
	data: any[]
	keyCollection: any[],
	selectedLevel: string,
}

const levelName = (value) => {
	var keyName = "";
	switch( value )
	{
		case -1:
			keyName = 'Not Set';
			break;

		case 1:
			keyName = "First";
			break;

		case 2:
			keyName = "Second";
			break;

		case 3:
			keyName = "Third";
			break;

		case 4:
			keyName = "Fourth";
			break;

		default: 
			keyName = 'Error';
			break;
	}

	return keyName;
}

const CustomTooltip = (props:any) => {
	if (props.payload && props.payload[0] != null) 
	{
		const newPayload = props.payload.map((payload)=>{
			var newPayload =  { ...payload };
			newPayload.name = payload.payload['antibiotic'];
			newPayload.value = payload.value + '% (' + payload.payload['susceptible'] + '/' + payload.payload['total'] + ')'; 
			return newPayload;
		});

		return <DefaultTooltipContent {...props} label={""} payload={newPayload} />;
	}
  
	return <DefaultTooltipContent {...props} />;
};

const ChartAntibiotics = (props:ChartAntibioticsProps) => {
	return (
		<>
			<Typography variant="h3">Antibiotics {props.selectedLevel != null ? <>- {levelName(props.selectedLevel)}</> : null}</Typography>
			<ResponsiveContainer height={(props.data.length * 30) + 40} width="100%">
				<BarChart data={props.data}  layout="vertical" barSize={20} margin={{left: 150}}>
					<CartesianGrid strokeDasharray="3 3"/>
					<XAxis type="number"  axisLine={false}  />
					<YAxis type="category" dataKey="antibiotic" orientation="left" interval={0} tick={{ width: 120, marginRight:'20px' }}/>
					<Tooltip content={<CustomTooltip/>} />
					<Bar dataKey={'value'}/>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
}

export default ChartAntibiotics;